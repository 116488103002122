<template>
	<div class="c-commissions-row">
		<div class="c-commissions-row__container --column container">
			<p class="c-commissions-row__text">
				(1) 0€ de comisión de mantenimiento en la cuenta en el caso de que algún titular de la
				cuenta cumpla con alguno de los siguientes requisitos a cierre del trimestre natural
				anterior a la fecha de aplicación:
			</p>
			<ul class="c-commissions-row__list">
				<li class="c-commissions-row__list-item">
					La suma de sus posiciones en CBNK como titular en cuentas corrientes, depósitos a la
					vista, depósitos a plazo, fondos de inversión, valores, seguros, planes de pensiones,
					préstamos (importe pendiente de pago) y créditos (importe dispuesto) es igual o
					superior a 100.000€.
				</li>
				<li class="c-commissions-row__list-item">
					Ser licenciado en Farmacia o Ingeniería de Caminos, Canales y Puertos.
				</li>
				<li class="c-commissions-row__list-item">
					Ser accionista de CBNK y tener un número de acciones igual o superior a 500.
				</li>
				<li class="c-commissions-row__list-item">
					Tener una edad inferior a 35 años. En este caso, para la no aplicación de la comisión
					es necesario que todos los titulares de la cuenta cumplan con esta condición.
				</li>
			</ul>
			<p class="c-commissions-row__text">
				En caso de no cumplir con al menos 1 de los requisitos mencionados, el coste de
				mantenimiento de cuenta será de 35 € al trimestre, por cuenta de pago, siempre que el
				cliente tenga una antigüedad mínima en la Entidad de 6 meses. La fecha de adeudo será el
				día 5 de cada trimestre natural.
			</p>
			<p class="c-commissions-row__text">Ejemplos representativos:</p>
			<p class="c-commissions-row__text">
				- TAE Escenario cobro de la comisión de mantenimiento de cuenta 0 € / trimestre y la
				comisión de emisión/renovación de la tarjeta de débito 0€ / anuales:
			</p>
			<p class="c-commissions-row__text">
				Tipo interés acreedor del 0%. TAE = 0,00%. Total comisiones = 0 euros. Total interés = 0
				€
			</p>
			<p class="c-commissions-row__text">
				- TAE Escenario cobro de la comisión de mantenimiento de cuenta 35 € / trimestre y la
				comisión de emisión/renovación de la tarjeta de débito 15€ / anuales:
			</p>
			<p class="c-commissions-row__text">
				Tipo interés acreedor del 0%. TAE = -3,08%. Total comisiones =155 euros. Total interés =
				0 €*
			</p>
			<p class="c-commissions-row__text">(*Calculadas siempre bajo un saldo de 5.000 euros)</p>
			<p class="c-commissions-row__text">
				Consulte
				<a
					class="c-commissions-row__link"
					href="http://www.bancocaminos.es/documents/20486/37761/DOC.+COMISIONES+CUENTA+TRANSPARENTE_BC.pdf/7d004e6b-f59c-46fc-9b0e-54f9a3d3297a"
					target="_blank"
				>
					AQUÍ
				</a>
				el documento informativo de comisiones.
			</p>

			<p class="c-commissions-row__text">
				(2) 0€ de comisión de mantenimiento de la tarjeta de débito en el caso de que algún
				titular de la cuenta cumpla con el siguiente requisito a cierre del trimestre natural
				anterior a la fecha de aplicación:
			</p>
			<ul class="c-commissions-row__list">
				<li class="c-commissions-row__list-item">
					La suma de sus posiciones en CBNK como titular en cuentas corrientes, depósitos a la
					vista, depósitos a plazo, fondos de inversión, valores, seguros, planes de pensiones,
					préstamos (importe pendiente de pago) y créditos (importe dispuesto) es igual o
					superior a 100.000€.
				</li>
				<li class="c-commissions-row__list-item">
					Ser licenciado en Farmacia o Ingeniería de Caminos, Canales y Puertos.
				</li>
				<li class="c-commissions-row__list-item">
					Ser accionista de CBNK y tener un número de acciones igual o superior a 500.
				</li>
				<li class="c-commissions-row__list-item">
					Tener una edad inferior a 35 años. En este caso, para la no aplicación de la comisión
					es necesario que todos los titulares de la cuenta cumplan con esta condición.
				</li>
			</ul>
			<p class="c-commissions-row__text">
				En caso de no cumplir con el requisito mencionado, el coste de mantenimiento de la
				tarjeta de débito será de 15 € anual por tarjeta emitida.
			</p>
			<p class="c-commissions-row__text">(2) Sustitución/duplicado de tarjeta de débito: 8 €.</p>
			<p class="c-commissions-row__text">
				(3) Disposición de efectivo en cajeros a débito: sin comisión en las 4 primeras
				disposiciones al mes en cualquier cajero de España, sin límite de importe. A partir del
				5º reintegro, para cualquier importe, se cobrará la comisión que cobre la entidad
				propietaria del cajero.
			</p>
		</div>
	</div>
</template>

<script>
export default {
	name: 'c-commissions-row',
};
</script>

<style lang="scss" scoped>
.c-commissions-row {
	display: flex;
	width: 100%;
}

.c-commissions-row__container {
	color: $color-secondary;
	display: flex;
	padding: 10px;
	font-family: 'Figtree';
	font-style: normal;
	font-weight: 400;
	font-size: 12px;
	line-height: 1.2;
}

.c-commissions-row__container.--column {
	flex-direction: column;
}

.c-commissions-row__text {
	padding-bottom: 8px;
}

.c-commissions-row__list {
	list-style-position: inside;
	padding-bottom: 8px;
	margin-left: 10px;
}

.c-commissions-row__link {
	color: $color-secondary;
	text-decoration: underline;
}
</style>
