<template>
	<div class="c-header-distancia">
		<div class="c-header-distancia__header">
			<div class="c-header-distancia__header-content">
				<div class="c-header-distancia__logo">
					<img
						src="@project/assets/img/logo-claim.svg"
						alt=""
						width="100%"
						height="100%"
					/>
				</div>
			</div>
		</div>
		<div class="c-header-distancia__body">
			<div class="c-header-distancia__container --space-between container-cbnk">
				<div class="c-header-distancia__column --translateY">
					<div class="c-header-distancia__content">
						<h1 class="c-header-distancia__banner">Alta a distancia</h1>

						<h1 class="c-header-distancia__title">
							Bienvenido al servicio de
							<br />
							alta a distancia de CBNK
						</h1>
					</div>
				</div>
			</div>
		</div>
		<div class="c-header-distancia__column-form --no-mobile">
			<div class="c-header-distancia__form">
				<c-landing-form-distancia
					:isButtonDisabled="isButtonDisabled"
					@submit="submit"
				/>
			</div>
		</div>
		<div class="c-header-distancia__floating">
			<button
				class="c-header-distancia__floating-button"
				:disabled="isButtonDisabled"
				@click="openRegisterFormModal"
			>
				Comenzar
			</button>
		</div>
	</div>
</template>

<script>
import DeviceDetector from 'device-detector-js';
import MRegisterForm from '@modals/m-register-form';
import CLandingFormDistancia from '@project/components/c-landing-form-distancia';

export default {
	name: 'c-header',

	components: {
		CLandingFormDistancia,
	},

	data() {
		return { isButtonDisabled: false };
	},

	methods: {
		submit(data) {
			this.isButtonDisabled = true;

			const { device } = new DeviceDetector().parse(navigator.userAgent);
			const { [process.env.VUE_APP_CURRENT_PROJECT]: entityId } = {
				cbnk: '0234',
			};
			// eslint-disable-next-line no-shadow
			const { name, mobilePhoneNumber, email } = data;
			const urlParams = new window.URLSearchParams(window.location.search);
			const utm = {
				source: urlParams.get('utm_source'),
				medium: urlParams.get('utm_medium'),
				campaign: urlParams.get('utm_campaign'),
				term: urlParams.get('utm_term'),
				content: urlParams.get('utm_content'),
			};
			const channelId = urlParams.get('channel') || 'e6cae6ed-078a-4581-bdaf-54b8481eab66';
			const subchannelId = urlParams.get('subchannel');
			const partner = urlParams.get('P');
			const officeId = urlParams.get('O');
			const vertical = urlParams.get('V');

			this.$store
				.dispatch('onboarding/createProcess', {
					entityId,
					device: {
						brand: device?.brand,
						model: device?.model,
					},
					name,
					mobilePhoneNumber,
					email,
					utm,
					channelId,
					subchannelId,
					origin: this.$store.state.onboarding.productId,
					acceptsTgss: false,
					partner,
					officeId,
					vertical,
					collective: vertical,
				})
				.then(() => this.$router.push('app-personal-data'))
				.catch(() => {})
				.finally(() => {
					this.isButtonDisabled = false;
				});
		},

		async openRegisterFormModal() {
			const data = await this.$store.dispatch('modal/open', {
				component: MRegisterForm,
				props: { fullscreen: true },
			});

			if (data) {
				this.submit(data);
			}
		},
	},
};
</script>

<style lang="scss" scoped>
.c-header-distancia {
	display: flex;
	flex-direction: column;
	width: 100%;
}

.c-header-distancia__header {
	display: flex;
	position: relative;
	width: 100%;
	background-color: white;
	justify-content: flex-start;
	padding: 32px;
}

.c-header-distancia__header-content {
	display: flex;
	padding: 18px;
	transform: translateX(320px);
}

.c-header-distancia__body {
	display: flex;
	width: 100%;
	min-height: 400px;
	background-image: url(~@project/assets/img/cbnk-landing-distancia.png);
	background-repeat: no-repeat;
	background-position: center;
	background-size: cover;
	font-family: 'Figtree', georgia, serif;
	font-weight: bold;
	border-bottom-left-radius: 20% 80%;
	justify-content: flex-start;
	margin-bottom: 32px;
}

.c-header-distancia__container {
	display: flex;
	padding: 18px;
	gap: 440px;
}

.c-header-distancia__logo {
	display: flex;
	position: relative;
	width: 375px;
	height: 49px;
}

.c-header-distancia__container.--header {
	background-color: $color-surface-light;
}

.c-header-distancia__container.--space-between {
	justify-content: space-between;
}

.c-header-distancia__container.--wrap {
	flex-wrap: wrap;
}

.c-header-distancia__column.--translateY {
	transform: translateY(100px) translateX(360px);
}

.c-header-distancia__column {
	display: flex;
	flex-direction: column;
}

.c-header-distancia__column-form {
	display: flex;
}

.c-header-distancia__content {
	color: $color-surface-light;
	padding-right: 10px;
}

.c-header-distancia__banner {
	display: flex;
	width: max-content;
	font-size: 1.3rem;
	font-weight: 600;
	text-align: left;
}

.c-header-distancia__title {
	font-size: 52px;
	font-size: 3.25rem;
	font-weight: 100;
	margin: 46px 0 32px;
}

.c-header-distancia__form {
	width: 100%;
	background: $color-surface-light;
}

.c-header-distancia__floating {
	display: none;
	position: fixed;
	width: 100%;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 1;
}

.c-header-distancia__floating-button {
	color: $color-surface-light;
	outline: none;
	appearance: none;
	user-select: none;
	touch-action: manipulation;
	white-space: nowrap;
	display: flex;
	width: 100%;
	background: $color-primary;
	border: 0;
	border-top-left-radius: 10px;
	border-top-right-radius: 10px;
	text-transform: uppercase;
	justify-content: center;
	align-items: center;
	padding: 16px 12px;
}

@media (max-width: 992px) {
	.c-header-distancia__header {
		justify-content: center;
	}

	.c-header-distancia__header-content {
		transform: translateX(0);
		padding: 0;
	}

	.c-header-distancia__body {
		height: 550px;
		border-bottom-left-radius: 0;
	}

	.c-header-distancia__logo {
		width: auto;
		height: auto;
	}

	.c-header-distancia__banner {
		display: none;
	}

	.c-header-distancia__column-form.--no-mobile {
		display: none;
	}

	.c-header-distancia__column.--translateY {
		transform: translateY(100px) translateX(0px);
	}

	.c-header-distancia__floating {
		display: flex;
	}

	.c-header-distancia__title {
		transform: translateY(50px);
		margin-left: 12px;
	}
}

@media (max-width: 576px) {
	.c-header-distancia__content {
		padding: 0px;
	}

	.c-header-distancia__title {
		font-size: 2.6rem;
	}
}

@media (max-width: 325px) {
	.c-header-distancia__title {
		font-size: 2.6rem;
	}
}
</style>
