<template>
	<div class="c-cbnk-landing-form">
		<div class="c-cbnk-landing-form__content">
			<form @submit.prevent="submit">
				<div class="c-cbnk-landing-form__group">
					<c-text-field
						v-model.trim="$v.name.$model"
						:placeholder="'Nombre'"
						autocomplete="given-name"
						:invalid="$v.name.$dirty && $v.name.$error"
						@blur="$v.name.$touch()"
					>
						<span slot="title">Nombre</span>
					</c-text-field>
				</div>
				<div class="c-cbnk-landing-form__group">
					<c-text-field
						v-model.number.trim="$v.mobilePhoneNumber.$model"
						:placeholder="'Teléfono'"
						autocomplete="tel"
						maxlength="9"
						:invalid="$v.mobilePhoneNumber.$dirty && $v.mobilePhoneNumber.$error"
						@blur="$v.mobilePhoneNumber.$touch()"
					>
						<span slot="title">Teléfono</span>
					</c-text-field>
				</div>
				<div class="c-cbnk-landing-form__group">
					<c-text-field
						v-model.trim="$v.email.$model"
						:placeholder="'nombre@correo.com'"
						autocomplete="email"
						:invalid="$v.email.$dirty && $v.email.$error"
						@blur="$v.email.$touch()"
					>
						<span slot="title">Correo electrónico</span>
					</c-text-field>
				</div>
				<div class="c-cbnk-landing-form__group">
					<c-text-field
						v-model.trim="$v.emailV.$model"
						:placeholder="'nombre@correo.com'"
						autocomplete="email"
						:invalid="$v.emailV.$dirty && $v.emailV.$error"
						@blur="$v.emailV.$touch()"
					>
						<span slot="title">Repite tu correo electrónico</span>
					</c-text-field>
				</div>
				<div class="c-cbnk-landing-form__consents">
					<div
						v-if="$store.state.onboarding.productId === 'CTATRANS'"
						class="c-cbnk-landing-form__checkbox"
						:class="{ '--error': requisitosError }"
					>
						<label
							class="c-cbnk-landing-form__checkbox-label"
							for="initial-data-requisitos"
						>
							<input
								class="c-cbnk-landing-form__checkbox-mark"
								id="initial-data-requisitos"
								type="checkbox"
								v-model="$v.requisitos.$model"
							/>
							Confirmo que cumplo con los requisitos de contratación&nbsp;
							<a
								href="#"
								title="Requisitos"
								role="button"
							>
								<span
									class="c-cbnk-landing-form__checkbox-info"
									@click="openRequirementsModal"
								>
									?
								</span>
							</a>
						</label>
					</div>
					<div
						class="c-cbnk-landing-form__checkbox"
						:class="{ '--error': condicionesError }"
					>
						<label
							class="c-cbnk-landing-form__checkbox-label"
							for="initial-data-condiciones"
						>
							<input
								class="c-cbnk-landing-form__checkbox-mark"
								id="initial-data-condiciones"
								type="checkbox"
								v-model="$v.condiciones.$model"
							/>
							Acepto la
							<a
								class="c-cbnk-landing-form__checkbox-link"
								:href="securityPolicyLink"
								target="_blank"
							>
								política de privacidad y protección de datos
							</a>
						</label>
					</div>
				</div>

				<p
					v-if="$store.state.onboarding.productId === 'CTATRANS'"
					class="c-cbnk-landing-form__pre-contractual"
				>
					Consulte nuestra
					<a
						:href="urls.contractual"
						target="_blank"
					>
						información pre-contractual
					</a>
				</p>

				<div class="c-cbnk-landing-form__data-protection">
					<div class="c-cbnk-landing-form__data-protection-scrolling">
						<c-data-protection></c-data-protection>
					</div>
				</div>

				<div class="c-cbnk-landing-form__submit">
					<button
						class="c-cbnk-landing-form__submit-button"
						type="submit"
						:disabled="isButtonDisabled"
					>
						Contratar
					</button>
				</div>
			</form>
		</div>
	</div>
</template>

<script>
import { alpha, email } from '@utils/validators';
import { mapErrorFields } from '@utils/helpers';
import { minLength, required, maxLength, numeric, helpers } from 'vuelidate/lib/validators';
import MRequirements from '@modals/m-requirements';
import MConsentTgss from '@modals/m-consent-tgss';
import CDataProtection from '@project/components/c-data-protection';
import CTextField from './c-cbnk-text-field';

const { VUE_APP_CURRENT_PROJECT } = process.env;

const sameAsEmail = (equalTo) => {
	return helpers.withParams({ type: 'sameAs', eq: equalTo }, function (value, parentVm) {
		const lowerCaseEmailV = value?.toLowerCase();
		const emailValue = helpers.ref(equalTo, this, parentVm);

		return lowerCaseEmailV === emailValue?.toLowerCase();
	});
};

export default {
	name: 'c-cbnk-landing-form',

	components: { CTextField, CDataProtection },

	props: { isButtonDisabled: Boolean },

	data() {
		return {
			name: '',
			mobilePhoneNumber: '',
			email: '',
			emailV: '',
			requisitos: false,
			condiciones: false,
		};
	},

	validations: {
		name: {
			alpha,
			required,
			maxLength: maxLength(45),
		},
		mobilePhoneNumber: {
			required,
			numeric,
			phoneMinLength: minLength(9),
			maxLength: maxLength(9),
			phoneNumber: helpers.withParams({ type: 'phoneNumber' }, (value) => {
				const s = `${value}`;
				return s.startsWith('6') || s.startsWith('7');
			}),
		},
		email: {
			required,
			email,
			maxLength: maxLength(255),
		},
		emailV: {
			required,
			sameAsEmail: sameAsEmail('email'),
		},
		requisitos: {
			accepted: (value) => value === true,
		},
		condiciones: {
			accepted: (value) => value === true,
		},
	},

	computed: {
		...mapErrorFields([
			'name',
			'mobilePhoneNumber',
			'email',
			'emailV',
			'requisitos',
			'condiciones',
		]),

		securityPolicyLink() {
			return 'https://www.cbnk.es/aviso-legal#politica-de-privacidad';
		},

		urls() {
			return {
				caminos: {
					contractual:
						'https://www.bancocaminos.es/documents/20486/217345/info_pre.pdf/49baae29-3ffd-430e-90ee-31ba31d9baa4',
					contract:
						'https://www.bancocaminos.es/documents/20486/246998/CONTRATO+MARCO+SERVICIOS+FINANCIEROS+BC.pdf',
				},
				bancofar: {
					contractual:
						'https://www.bancofar.es/documents/66903/217553/Precontractual_Cta_Transparente.pdf/98738488-cb10-45b5-b2d7-559ed7e53dc3',
					contract:
						'https://www.bancofar.es/documents/66903/246939/CONTRATO+MARCO+SERVICIOS+FINANCIEROS+BF.pdf',
				},
				cbnk: {
					contractual:
						'https://cbnk.es/dam/ASSETS-EXT/016-CANALES-DIGITALES/001-ONBOARDING/002-DOCUMENTOS/info_pre.pdf',
					contract:
						'https://www.bancocaminos.es/documents/20486/246998/CONTRATO+MARCO+SERVICIOS+FINANCIEROS+BC.pdf',
				},
			}[VUE_APP_CURRENT_PROJECT];
		},
	},

	methods: {
		submit() {
			// eslint-disable-next-line no-shadow
			const { name, mobilePhoneNumber, email, emailV, $v } = this;

			if (this.$store.state.onboarding.productId === 'DISTANCIA') {
				this.requisitos = true;
			}

			$v.$touch();

			if (!$v.$invalid) {
				this.$emit('submit', {
					name,
					mobilePhoneNumber,
					email: email?.toLowerCase(),
					emailV: emailV?.toLowerCase(),
				});
			}
		},

		openRequirementsModal() {
			return this.$store.dispatch('modal/open', MRequirements);
		},

		openConsentModal() {
			return this.$store.dispatch('modal/open', {
				component: MConsentTgss,
				props: { companyName: this.$store.state.app.companyName },
			});
		},
	},
};
</script>

<style lang="scss" scoped>
.c-cbnk-landing-form {
	width: 100%;
}

.c-cbnk-landing-form__group {
	padding-bottom: 10px;
}

.c-cbnk-landing-form__consents {
	margin-top: 12px;
}

.c-cbnk-landing-form__checkbox.--error {
	color: #fb5f58;
	a {
		color: #fb5f58;
	}
}

.c-cbnk-landing-form__checkbox-mark {
	margin-right: 5px;
}

.c-cbnk-landing-form__checkbox-label {
	cursor: pointer;
	margin: 4px 0;
	font-weight: normal;
}

.c-cbnk-landing-form__checkbox-link {
	color: $color-secondary;
	text-decoration: underline;
}

.c-cbnk-landing-form__checkbox-info {
	color: white;
	display: inline-block;
	width: 16px;
	height: 16px;
	border-radius: 50%;
	background: $color-secondary;
	text-align: center;
	line-height: 16px;
	font-weight: bold;
	font-style: normal;
}

.c-cbnk-landing-form__submit {
	display: flex;
	justify-content: center;
}

.c-cbnk-landing-form__submit-button {
	outline: none;
	user-select: none;
	appearance: none;
	white-space: nowrap;
	color: $color-surface-light;
	display: block;
	width: 120px;
	border: 0;
	border-radius: 20px;
	padding: 10px 12px;
	margin-top: 30px;
	background-color: $color-primary;
	font-size: small;
	font-weight: bold;
}

.c-cbnk-landing-form__submit-button:disabled {
	color: $color-text-disabled;
	background-color: $color-disabled;
}

.c-cbnk-landing-form__pre-contractual {
	color: $color-primary;
	font-size: 0.75rem;
	font-weight: 600;
	margin: 10px 0;
	a {
		color: $color-primary;
		text-decoration: underline;
	}
}

.c-cbnk-landing-form__data-protection {
	display: flex;
	position: relative;
	width: 100%;
	height: 100px;
	margin-top: 20px;
	background: $color-disabled;
	overflow: hidden;
}

.c-cbnk-landing-form__data-protection-scrolling {
	overflow-x: hidden;
	overflow-y: scroll;
}

@media (max-width: 992px) {
	.c-cbnk-landing-form__submit-button {
		width: 100%;
		margin: 12px;
	}
}
</style>
