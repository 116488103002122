const SET_IS_ENG = 'SET_IS_ENG';
const SET_IS_SAL = 'SET_IS_SAL';
const SET_VERTICAL = 'SET_VERTICAL';
const SET_COLLECTIVE = 'SET_COLLECTIVE';

export default {
	app: {
		state: {
			companyId: 'BC',
			companyName: 'CBNK BANCO DE COLECTIVOS S.A.',
			isCBNK: true,
			isENG: false,
			isSAL: false,
			vertical: null,
			collective: null,
			informacionPrecontractual:
				'https://www.bancocaminos.es/documents/20486/217345/info_pre.pdf/49baae29-3ffd-430e-90ee-31ba31d9baa4',
			contratoMarco:
				'https://www.bancocaminos.es/documents/20486/246998/CONTRATO+MARCO+SERVICIOS+FINANCIEROS+BC.pdf/',
			telephone: '900 107 411',
			url: 'https://www.cbnk.es',
			privacyPolicyURL: 'https://www.cbnk.es/aviso-legal#politica-de-privacidad',
			documentTitle: 'CBNK - Alta a distancia',
		},

		mutations: {
			[SET_IS_ENG](state, value) {
				state.isENG = value;
			},
			[SET_IS_SAL](state, value) {
				state.isSAL = value;
			},
			[SET_VERTICAL](state, value) {
				state.vertical = value;
			},
			[SET_COLLECTIVE](state, value) {
				state.collective = value;
			},
		},

		actions: {
			setIsEng({ commit }) {
				commit(SET_IS_ENG, true);
				commit(SET_VERTICAL, 1);
				commit(SET_COLLECTIVE, 1);
			},
			setIsSal({ commit }) {
				commit(SET_IS_SAL, true);
				commit(SET_VERTICAL, 2);
				commit(SET_COLLECTIVE, 2);
			},
		},
	},
};
